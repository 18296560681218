import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import league from "./modules/league";
import pais from "./modules/mixins/pais";
import state from "./modules/mixins/state";
import city from "./modules/mixins/city";
import town from "./modules/mixins/town";
import file from "./modules/mixins/file";
import typedocuments from "./modules/mixins/typedocuments";
import organizations from "./modules/mixins/organizations";
import gender from "./modules/mixins/gender";
import turn from "./modules/mixins/turn";
import clubes from "./modules/clubes";
import athletes from "./modules/athletes";
import events from "./modules/events";
import available from "./modules/available";
import categories from "./modules/categories";
import tests from "./modules/mixins/tests";
import times from "./modules/mixins/times";
import calendar from "./modules/calendar";
import inscriptions from "./modules/inscriptions";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    dataPeople: [],
    isDevice: false,
    isLeague: false,
    isClube: false,
    btnActive: null,
  },
  modules: {
    login: login,
    league: league,
    pais: pais,
    state: state,
    city: city,
    town: town,
    file: file,
    clubes: clubes,
    athletes: athletes,
    typedocuments: typedocuments,
    organizations: organizations,
    gender: gender,
    events: events,
    available: available,
    categories: categories,
    tests: tests,
    times: times,
    turn: turn,
    calendar: calendar,
    inscriptions: inscriptions,
  },
});
