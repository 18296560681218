import Vue from "vue";
import './plugins/axios'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/_dm_custom.scss';
import './styles/_dm_custom_class.scss';

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VueTreeList from 'vue-tree-list'

import i18n from './i18n';

import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library

import VueDragTree from 'vue-drag-tree';
import 'vue-drag-tree/dist/vue-drag-tree.min.css';

Vue.use(VueEasytable);
Vue.use(VueTreeList);

Vue.use(VueSweetalert2);
Vue.use(VueDragTree);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store: store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
