import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataEvents: false,
  loadinggetDataEvents: false,
  itemsDataEvents: [],
  itemsEventJson: [],
  itemsDataEventsXlsxResult: [],
  itemsEventSelected: [],
  objectDataEvent: [],
  loadingDataEventTime: false,
  itemsDataEventsTime: [],
  events_name: null,
  events_date_begin: null,
  events_date_end: null,
  events_location: null,
  events_date_close: null,
  events_available: null,
  events_quantity_team: null,
  events_quantity_test: null,
  events_id_pais: null,
  events_id_state: null,
  events_id_town: null,
  events_id_city: null,
  events_id_inspector: null,
  events_name_responsible_technician: null,
  events_email_responsible_technician: null,
  events_tests_selected: [],
  events_tests_categories: [],
  events_upload_delegate: null,
  events_free_event: null,
  events_status: null,
  events_available: 5,
  itemsDataEventsXlsx: [],
  events_modal_days_settings: false,
  events_time_id: null,
  events_time_day: null,
  events_test_generated: [],
  events_disabled_bool: true,
  events_number_of_track: null,
  events_allowed_upload_one_level: null,
  events_allowed_change_level: null,

  events_file_modal_id: false,
  events_file_modal_name: null,

  //MODAL
  events_modal_id_photo: false,
  events_modal_id_league: false,
  events_modal_import_data: false,

  events_modal_data_test_selecting: false,
  events_modal_data_test_data: [],
  events_tests_selected_test: [],
  events_tests_selected_origin: [],
  events_tests_selected_one: null,
  events_tests_segments_selected_one: null,
  events_tests_id_gender: 1,
};

const mutations = {
  setEvents(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;

    let bool_free_event = 0;
    let bool_upload_delegates = 0;

    if (state.events_free_event == true) {
      bool_free_event = 1;
    } else {
      bool_free_event = 0;
    }

    if (state.events_upload_delegate == true) {
      bool_upload_delegates = 1;
    } else {
      bool_upload_delegates = 0;
    }

    var data = JSON.stringify({
      name: state.events_name,
      long_name: state.events_long_name,
      date_begin: state.events_date_begin,
      date_end: state.events_date_end,
      location: state.events_location,
      date_end_inscription: state.events_date_close,
      id_association: state.events_available,
      quantity_test_people: state.events_quantity_test,
      quantity_test_team: state.events_quantity_team,
      name_people_tech: state.events_name_responsible_technician,
      email_people_tech: state.events_email_responsible_technician,
      details: state.events_details,
      status: 1,
      id_organization: payload.id_org,
      id_inspector: state.events_id_inspector,
      id_pais_location: state.events_id_pais,
      id_state_location: state.events_id_state,
      id_city_location: state.events_id_city,
      id_town_location: state.events_id_town,
      free_event: bool_free_event,
      upload_technical: bool_upload_delegates,
      number_of_track: state.events_number_of_track,
      allowed_upload_one_level: state.events_allowed_upload_one_level ? 1 : 0,
      allowed_change_level: state.events_allowed_change_level ? 1 : 0,
      itemsCategories: state.events_tests_selected,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/events/save",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Event created successfully",
          showConfirmButton: true,
        });

        state.objectDataEvent = response.data.data;

        router
          .push({
            name: "formEventsTests",
            params: { id_event: state.objectDataEvent.id },
          })
          .then(() => {
            router.go();
          });
        state.events.events_disabled_bool = true;
        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  updateEvents(state, payload) {
    state.loadingDataUpdateEvents = true;
    var vm = this;

    let bool_free_event = 0;
    let bool_upload_delegates = 0;

    if (state.events_free_event == true) {
      bool_free_event = 1;
    } else {
      bool_free_event = 0;
    }

    if (state.events_upload_delegate == true) {
      bool_upload_delegates = 1;
    } else {
      bool_upload_delegates = 0;
    }

    var data = JSON.stringify({
      name: state.events_name,
      long_name: state.events_long_name,
      date_begin: state.events_date_begin,
      date_end: state.events_date_end,
      location: state.events_location,
      date_end_inscription: state.events_date_close,
      id_association: state.events_available,
      quantity_test_people: state.events_quantity_test,
      quantity_test_team: state.events_quantity_team,
      name_people_tech: state.events_name_responsible_technician,
      email_people_tech: state.events_email_responsible_technician,
      details: state.events_details,
      status: 1,
      id_organization: payload.id_org,
      id_inspector: state.events_id_inspector,
      id_pais_location: state.events_id_pais,
      id_state_location: state.events_id_state,
      id_city_location: state.events_id_city,
      id_town_location: state.events_id_town,
      free_event: bool_free_event,
      upload_technical: bool_upload_delegates,
      number_of_track: state.events_number_of_track,
      allowed_upload_one_level: state.events_allowed_upload_one_level ? 1 : 0,
      allowed_change_level: state.events_allowed_change_level ? 1 : 0,
      itemsCategories: state.events_tests_selected,
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/events/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Evento actualizado",
          showConfirmButton: true,
        });

        state.objectDataEvent = response.data.data;

        router.go();

        state.events.events_disabled_bool = true;
        state.loadingDataUpdateEvents = false;
      })
      .catch(function (error) {
        state.loadingDataUpdateEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  cleanEvents(state) {
    state.loadingDataEvents = false;
    state.loadinggetDataEvents = false;
    state.itemsDataEvents = [];
    state.itemsDataEventsXlsxResult = [];
    state.objectDataEvent = [];
    state.loadingDataEventTime = false;
    state.itemsDataEventsTime = [];
    state.events_name = null;
    state.events_date_begin = null;
    state.events_date_end = null;
    state.events_location = null;
    state.events_date_close = null;
    state.events_available = null;
    state.events_quantity_team = null;
    state.events_quantity_test = null;
    state.events_id_pais = null;
    state.events_id_state = null;
    state.events_id_town = null;
    state.events_id_city = null;
    state.events_id_inspector = null;
    state.events_name_responsible_technician = null;
    state.events_email_responsible_technician = null;
    state.events_tests_selected = [];
    state.events_tests_categories = [];
    state.events_upload_delegate = null;
    state.events_free_event = null;
    state.events_status = null;
    state.events_available = 5;
    state.itemsDataEventsXlsx = [];
    state.events_modal_days_settings = false;
    state.events_time_id = null;
    state.events_time_day = null;
    state.events_test_generated = [];
    state.events_disabled_bool = true;

    state.events_file_modal_id = false;
    state.events_file_modal_name = null;

    //MODAL
    state.events_modal_id_photo = false;
    state.events_modal_id_league = false;
    state.events_modal_import_data = false;

    state.events_modal_data_test_selecting = false;
    state.events_modal_data_test_data = [];
    state.events_tests_selected_test = [];
    state.events_tests_selected_origin = [];
    state.events_tests_selected_one = null;
    state.events_tests_segments_selected_one = null;
    state.events_tests_id_gender = 1;
  },

  setEventsTimes(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      id_event: payload.id_event,
      id_time: state.events_time_id,
      day_time: state.events_time_day,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/events/time/save",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        vm.commit("events/getEventsTime", { id_event: payload.id_event });
        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  setEventsJson(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      id_event: payload.id_event,
      json: payload.json,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/events/json/save",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
       
        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteEventsJson(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      id_event: payload.id_event,
    });
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/events/json/" + payload.id_event,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
       
        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        
      });
  },

  getEventsJson(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      id_event: payload.id_event,
    });
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/events/json/" + payload.id_event,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
       state.itemsEventJson = response.data.data;
        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.itemsEventJson = [];
        state.loadingDataEvents = false;
      });
  },

  setEventsMultiple(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      itemsevents: state.itemsDataEventsXlsx,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/events/multiple/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "events imported successfully",
          showConfirmButton: true,
        });

        state.itemsDataEventsXlsxResult = response.data.success;

        vm.commit("events/getEvents", { id_org: payload.id_organization });
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        console.log(error.response.data.message);
      });
  },

  updateEventsOld(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var data = JSON.stringify({
      id_type_document: state.events_type_document,
      document: state.events_document,
      names: state.events_names,
      last_name: state.events_father_last_name,
      mother_last_name: state.events_mother_last_name,
      id_gender: state.events_gender,
      birthday: state.events_date_of_bird,
      id_pais_location: state.events_id_pais,
      id_state_location: state.events_id_state,
      id_city_location: state.events_id_city,
      id_town_location: state.events_id_town,
      address_location: state.events_address,
      height: state.events_physical_height,
      weight: state.events_physical_weight,
      footwear: state.events_sizes_footwear,
      pole_size: state.events_sizes_pole,
      pants_size: state.events_sizes_pants,
      mail: state.events_email,
      phone: state.events_phone,
      status: 1,
      id_organization: payload.id_org,
      id_photo: state.events_id_photo,
      id_federation: state.events_entitie_id_federation,
      id_league: state.events_entitie_id_league,
      id_club: state.events_entitie_id_club,
      itemsRoles: [
        {
          id_events: payload.id,
          id_role: 3,
        },
      ],
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/events/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Atleta actualizada éxitosamente",
          showConfirmButton: true,
        });

        vm.commit("events/cleanEvents");
        router.back();

        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteEvents(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    let data = {
      status: 0,
    };
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/events/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Evento eliminado exitosamente",
          showConfirmButton: true,
        });

        vm.commit("events/getEvents", { id_org: payload.id_org });

        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getEvents(state, payload) {
    state.loadinggetDataEvents = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/events/list/all/" + payload.id_org,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataEvents = response.data.data;

        state.loadinggetDataEvents = false;
      })
      .catch(function (error) {
        state.loadinggetDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getEventsTime(state, payload) {
    state.loadingDataEventTime = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API + "/events/time/list/" + payload.id_event,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataEventsTime = response.data.data;
        state.loadingDataEventTime = false;
      })
      .catch(function (error) {
        state.loadingDataEventTime = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getEventsOne(state, payload) {
    state.loadinggetDataEvents = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/events/list/one/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(async function (response) {
        state.events_name = response.data.data[0].event.name;
        state.events_date_begin = response.data.data[0].event.date_begin;
        state.events_date_end = response.data.data[0].event.date_end;
        state.events_id_pais = response.data.data[0].event.id_pais_location;
        vm.commit("state/getState", {
          id_pais: response.data.data[0].event.id_pais_location,
        });
        state.events_id_state = response.data.data[0].event.id_state_location;
        vm.commit("city/getCity", {
          id_state: response.data.data[0].event.id_state_location,
        });
        state.events_id_city = response.data.data[0].event.id_city_location;
        vm.commit("town/getTown", {
          id_city: response.data.data[0].event.id_city_location,
        });
        state.events_id_town = response.data.data[0].event.id_town_location;
        state.events_location = response.data.data[0].event.location;
        state.events_date_close =
          response.data.data[0].event.date_end_inscription;
        state.events_available = response.data.data[0].event.id_association;
        state.events_id_inspector = response.data.data[0].event.id_inspector;
        state.events_quantity_team =
          response.data.data[0].event.quantity_test_people;
        state.events_quantity_test =
          response.data.data[0].event.quantity_test_team;
        state.events_name_responsible_technician =
          response.data.data[0].event.name_people_tech;
        state.events_email_responsible_technician =
          response.data.data[0].event.email_people_tech;
        state.events_free_event =
          response.data.data[0].event.free_event == 1 ? true : false;
        state.events_upload_delegate =
          response.data.data[0].event.upload_technical == 1 ? true : false;
        state.events_number_of_track =
          response.data.data[0].event.number_of_track;
        state.events_allowed_upload_one_level = response.data.data[0].event
          .allowed_upload_one_level
          ? true
          : false;
        state.events_allowed_change_level = response.data.data[0].event
          .allowed_change_level
          ? true
          : false;

        state.events_tests_selected_origin = response.data.data[0].categories;

        state.itemsEventSelected = response.data.data[0];

        let dataCategories = [];

        response.data.data[0].categories.map((item) => {
          dataCategories.push(item.id_category);
        });

        state.events_tests_selected = dataCategories;
        state.events_tests_selected_one = dataCategories[0];

        state.loadinggetDataEvents = false;
      })
      .catch(function (error) {
        state.loadinggetDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteEventsTime(state, payload) {
    state.loadingDataEvents = true;
    var vm = this;
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/events/time/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        vm.commit("events/getEventsTime", { id_event: payload.id_event });

        state.loadingDataEvents = false;
      })
      .catch(function (error) {
        state.loadingDataEvents = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  /*  cleanEvents(state) {
    state.events_type_document = null;
    state.events_document = null;
    state.events_names = null;
    state.events_father_last_name = null;
    state.events_mother_last_name = null;
    state.events_date_of_bird = null;
    state.events_id_pais = null;
    state.events_id_state = null;
    state.events_id_city = null;
    state.events_id_town = null;
    state.events_address = null;
    state.events_phone = null;
    state.events_email = null;
    state.events_gender = null;
    state.events_physical_height = null;
    state.events_physical_weight = null;
    state.events_number_events = null;
    state.events_sizes_footwear = null;
    state.events_sizes_pole = null;
    state.events_sizes_pants = null;
    state.events_entitie_id_federation = null;
    state.events_entitie_id_league = null;
    state.events_entitie_id_club = null;
    state.events_file_modal_id = false;
    state.events_file_modal_name = null;
    state.itemsDataEventsXlsx = [];
  }, */
};

const actions = {
  setEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setEvents", payload);
      resolve();
    });
  },

  updateEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateEvents", payload);
      resolve();
    });
  },

  setEventsTimes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setEventsTimes", payload);
      resolve();
    });
  },

  setEventsJson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setEventsJson", payload);
      resolve();
    });
  },

  deleteEventsJson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteEventsJson", payload);
      resolve();
    });
  },

  getEventsJson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEventsJson", payload);
      resolve();
    });
  },

  setEventsMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setEventsMultiple", payload);
      resolve();
    });
  },

  updateEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateEvents", payload);
      resolve();
    });
  },

  deleteEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteEvents", payload);
      resolve();
    });
  },

  deleteEventsTime({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteEventsTime", payload);
      resolve();
    });
  },

  getEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEvents", payload);
      resolve();
    });
  },

  getEventsTime({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEventsTime", payload);
      resolve();
    });
  },

  getEventsOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEventsOne", payload);
      resolve(true);
    });
  },

  cleanEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("cleanEvents", payload);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
